
import Vue from "vue";
import Intro from "@/components/Intro.vue";

export default Vue.extend({
  name: "App",
  components: {
    Intro,
  },
  mounted(): void {
    if (localStorage.getItem("showIntro") !== null) {
      this.showIntro = localStorage.getItem("showIntro") === "true";
    }
  },
  data() {
    return {
      showIntro: true,
    };
  },
  watch: {
    showIntro(value: boolean): void {
      localStorage.setItem("showIntro", value.toString());
    }
  },

});
