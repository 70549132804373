
import Vue from "vue";

export default Vue.extend({
  name: "Intro",
  methods: {
    closeIntro() {
      // @ts-ignore
      this.$parent.showIntro = false;
    }
  }
});
